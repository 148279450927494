<template>
  <div>
    <div class="locale">
      <h2><a-button @click="$router.back()" shape="circle" icon="arrow-left"/>添加题目</h2>
      <ol>
        <li><router-link to="/">后台</router-link></li>
        <li><a href="javascript:;">测验</a></li>
        <li><a href="javascript:;">题目</a></li>
        <li>修改</li>
      </ol>
    </div>

        <a-form-model ref="form" :rules="form.rules" :model="form.data" v-bind="form.layout" @submit="onSubmit" @submit.native.prevent>

            <div class="box">
                  <div class="box-head">
                    <h3>题目信息</h3>
                  </div>
              <div class="row">
                <div class="col xs-6">
                  <div class="box-body">
                    <a-form-model-item prop="image" label="题目图片">
                      <div class="row">
                        <div class="col xs-12">
                          <van-uploader multiple :max-count="1" v-model="form.images" />
                        </div>
                        <div class="col xs-12">
                          <a-avatar shape="square" :size="78" :src="item.image"/>
                        </div>
                      </div>
                    </a-form-model-item>


                    <a-form-model-item prop="diagram" label="调解图片">
                      <div class="row">
                        <div class="col xs-12">
                          <van-uploader multiple :max-count="1" v-model="form.diagrams" />
                        </div>
                        <div class="col xs-12">
                          <a-avatar shape="square" :size="78" :src="item.diagram"/>
                        </div>
                      </div>
                    </a-form-model-item>
                  </div>

                </div>
                <div class="col xs-10">
                  <div class="box-body">
                    <a-form-model-item prop="role_id" label="角色">
                      <a-select :options="roles" v-model="form.data.role_id" />
                    </a-form-model-item>
                    
                    <a-form-model-item prop="age" label="年龄">
                      <a-input type="number" placeholder="年龄几岁" v-model="form.data.age" />
                    </a-form-model-item>

                    <a-form-model-item prop="question" label="问题">
                      <a-input type="text" placeholder="问题" v-model="form.data.question" />
                    </a-form-model-item>
                    
                    <a-form-model-item prop="type" label="题型">
                      <a-select :options="types" v-model="form.data.type" />
                    </a-form-model-item>

                    <a-form-model-item label="答案选项">
                      <table class="list options">
                        <tr v-for="(opt,ind) in form.options" :key="ind" @click="selOption(ind, opt)" :class="{ on: opt.selected }">
                          <td >
                            <a-input type="text" v-model="opt.answer" :prefix="ALPHABETS[ind]+'.'">
                              <a-icon slot="suffix" type="check" v-if="opt.selected" style="color:green;" />
                            </a-input>
                            
                          </td>
                          <td class="action">
                            <a-button-group size="small">
                              <a-button onClick="event.cancelBubble = true" @click="delOption(ind)" v-if="form.options.length > 1"><a-icon type="minus" /></a-button>
                              <a-button onClick="event.cancelBubble = true" @click="addOption()" v-if="ind == form.options.length-1"><a-icon type="plus" /></a-button>
                            </a-button-group>
                          </td>
                        </tr>
                      </table>
                    </a-form-model-item>

                  </div>
                </div>
                <div class="col xs-8">
                  <div class="box-body">

                    <a-form-model-item prop="context" label="背景交代">
                      <a-textarea rows="4"  placeholder="背景交代" v-model="form.data.context" />
                    </a-form-model-item>

                    <a-form-model-item prop="explain" label="民法解释">
                      <a-textarea rows="4"  placeholder="民法解释" v-model="form.data.explain" />
                    </a-form-model-item>


                    <a-form-model-item prop="mediate" label="调解内容">
                      <a-textarea rows="4"  placeholder="调解内容" v-model="form.data.mediate" />
                    </a-form-model-item>

                    <a-form-model-item prop="remark" label="备注">
                      <a-textarea rows="2"  placeholder="备注" v-model="form.data.remark" />
                    </a-form-model-item>
                  </div>
                </div>
              </div>
            </div>


          <div class="submit">
            <a-button type="primary" html-type="submit">确认修改</a-button>
          </div>

        </a-form-model>
  </div>
</template>

<script>
  export default {
    name: 'TestRoleCreate',
    data () {
      return {
        item: {},
        roles: [ { label: '- 选择角色 -', value: '' } ],
        types: [ { label: '- 选择题型 -', value: '' } ],
        form: {
          layout: {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
          },
          rules: {
            role_id: [{ required: true, message: '请选择角色' }], 
            age: [{ required: true, message: '年龄不可为空' }], 
            context: [{ required: true, message: '背景交代不可为空' }],
            explain: [{ required: true, message: '民法解释不可为空' }],
            question: [{ required: true, message: '问题不可为空' }],
          },
          images: [], diagrams: [],
          options: [
            { answer: '这里是选项1', selected: true },
            { answer: '这里是选项2', selected: false },
            { answer: '这里是选项3', selected: false },
            { answer: '这里是选项4', selected: false },
            { answer: '这里是选项5', selected: false }
          ],
          data: {
            role_id: '', age: '', name: '', type: 'radio',
            context: '', explain: '', mediate: '',
            question: '', answer: [], remark: ''
          }
        }
      }
    },
    mounted() {
      let id = this.$route.params.id;
      this.$get('/test/issue/change/' + id).then( res => {
        let ajax = res.data;
        if (ajax.code == 0) {
          this.roles.push(...ajax.data.roles);
          this.types = ajax.data.types;
          this.item = ajax.data.item;
          for (let k in this.form.data) {
            if (ajax.data.item[k] && ajax.data.item[k].value) {
              this.form.data[k] = ajax.data.item[k].value;
            } else {
              if (ajax.data.item[k] !== null) {
                this.form.data[k] = ajax.data.item[k];
              }
            }
          }

          this.form.options = ajax.data.item.options.map(answer=>{
            return { answer: answer, selected: ajax.data.item.answer.indexOf(answer) != -1 };
          })
        }
      })
    },

    methods: {
      addOption() {
        this.form.options.push({
          answer: '新选项', selected: false
        })
      },
      delOption(i) {
        let index = this.form.data.answer.indexOf(this.form.options[i].answer)
        // console.log("i:", i, " index:", index)
        if(index!=-1){
          this.form.data.answer.splice(index, 1);
        }
        
        this.form.options.splice(i, 1);
      },
      selOption(ind, opt) {
        if(this.form.data.type=='radio'){
          this.form.options.forEach(opt=>{
            opt.selected = false;
          })
          opt.selected = true;
          // this.form.data.answer = opt.answer;
          this.form.data.answer = [opt.answer];
        }else{
          opt.selected = !opt.selected
          let index = this.form.data.answer.indexOf(opt.answer)
          if(opt.selected) {
            
            if(index==-1){
              this.form.data.answer.push(opt.answer);
            }
          }else{
            this.form.data.answer.splice(index, 1);
          }
        

        }
        
      },


      onSubmit(){
        this.$refs['form'].validate(valid => {
          if (valid) {
            this.create();
          }
        });
      },
      create (){
        let form = new FormData();

        if (this.form.images.length > 0) {
          form.append("image", this.form.images[0].file);
        }
        if (this.form.diagrams.length > 0) {
          form.append("diagram", this.form.diagrams[0].file);
        }

        this.form.data.answer = []
        this.form.options.forEach(opt=>{
          form.append("options", opt.answer);
          if (opt.selected) {
            // this.form.data.answer = opt.answer; 
            // this.form.data.append("answer", opt.answer);
            this.form.data.answer.push(opt.answer);
          }
        })

        for (let k in this.form.data) {
          form.append(k, this.form.data[k])
        }

        let id = this.$route.params.id;
        this.$post('/test/issue/change/'+id, form).then(res => {
          let ajax = res.data;
          if (ajax.code == 0) {
            setTimeout(()=>{
              this.$router.go(-1);
            }, 500);

          }
        })

      }
    }
  }
</script>
<style lang="less" scoped>
  table.list.options {}
  table.list.options th {
    padding-left: 8px; text-align: center;
  }
  table.list.options td {
  }
  table.list.options td.action {
    width: 80px;
  }
  table.options .ant-input {
    background: transparent;
  }

  table.options tr.on {
    background: #d3e5bc;
  }
  .list tr:nth-of-type(even){
    background: transparent;
  }
</style>
